import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";

import SwipeableViews from "react-swipeable-views";

import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import DoneIcon from "@mui/icons-material/Done";

import { styled, useTheme } from "@mui/material/styles";

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import SanityClient from "../libs/SanityClient";
import { green, grey, red } from "@mui/material/colors";

const Ride = ({ reverse = false }) => {
  const [loading, setLoading] = useState(true);
  const [ride, setRide] = useState(null);
  const [marks, setMarks] = useState([]);
  const [step, setStep] = useState(0);
  const [records, setRecords] = useState([]);
  const [recordCount, setRecordCount] = useState(0);
  const [sendingRequest, setSendingRequest] = useState(false);

  const { rideId } = useParams();
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();
  const action = (snackbarId) => (
    <CircularProgress size={20} sx={{ color: "white", px: 2 }} />
  );

  const CircleButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.accent.main,
    color: "#fff",
    border: "8px solid #fff",
    borderRadius: theme.spacing(10),
    width: theme.spacing(15),
    height: theme.spacing(15),
    padding: theme.spacing(2),
    fontSize: theme.spacing(6),
    "&:hover": {
      backgroundColor: theme.palette.accent.main,
    },
    "&.Mui-disabled": {
      backgroundColor: grey[400],
      color: "#fff",
      opacity: 0.5,
    },
  }));

  const LittleCircleButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#fff",
    color: "#000",
    borderRadius: theme.spacing(4),
    minWidth: theme.spacing(6),
    width: theme.spacing(6),
    height: theme.spacing(6),
    fontSize: theme.spacing(4),
    boxShadow: theme.shadows[3],
    paddingX: 0,
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#fff",
    },
  }));

  const sendRequest = () => {
    setSendingRequest(true);

    const stop_records = records.map((record, i) => ({
      _key: i.toString(),
      _type: "stop_record",
      arrived_at: record.arrived_at || localStorage.getItem("rideStartDate"),
      count: record.count,
      stop: {
        _type: "reference",
        _ref: record.stop._id,
      },
    }));

    const _record = {
      _type: "record",
      ride: {
        _type: "reference",
        _ref: ride._id,
      },
      started_at: localStorage.getItem("rideStartDate"),
      completed_at: new Date().toISOString(),
      stop_records: stop_records,
      reverse: reverse ? true : false,
      user: {
        _type: "reference",
        _ref: JSON.parse(localStorage.getItem("user"))._id,
      },
    };

    setTimeout(() => {
      SanityClient.create(_record)
        .then((res) => {
          setSendingRequest(false);
          window.location.href = `/statistiques/${res._id}`;
        })
        .catch((err) => {
          console.log(err);
          setSendingRequest(false);
        });
    }, 1000);
  };

  useEffect(() => {
    setLoading(true);
    SanityClient.fetch(
      `*[_type == "ride" && _id == "${rideId}"]{
        _id,
        name,
        stops[]->{
          _id,
          name,
          slug,
          city->{
            name,
            slug,
          }
        }
      }`
    )
      .then((res) => {
        if (reverse) {
          const stops = res[0].stops.reverse();
          setRide({ ...res[0], stops }, setLoading(false));
        } else {
          setRide(res[0], setLoading(false));
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (ride) {
      document.title = ride.name;
      const _labels = ride.stops.map((stop, i) => {
        return { value: i, label: stop.name };
      });
      setMarks(_labels);

      const _records = ride.stops.map((stop, i) => {
        return { stop: stop, count: 0, arrived_at: null };
      });

      setRecords(_records);
    }
  }, [ride]);

  useEffect(() => {
    if (records.length > 0) {
      const _count = records.reduce((acc, cur) => {
        return acc + cur.count;
      }, 0);
      setRecordCount(_count);
    }
    console.log(records);
  }, [records]);

  useEffect(() => {
    if (step && records[step - 1].arrived_at === null) {
      const _records = [...records];
      _records[step - 1].arrived_at = new Date().toISOString();
      setRecords(_records);
    }
  }, [step]);

  return (
    <Box>
      {loading && (
        <Stack
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ minHeight: "100vh" }}
        >
          <CircularProgress />
        </Stack>
      )}
      {!loading && ride && (
        <Container>
          <Stack
            direction="column"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
          >
            <Box sx={{ width: "100%" }}>
              <Typography
                variant="h5"
                component="h1"
                my={1}
                color={
                  reverse
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main
                }
                sx={{ fontWeight: "bold", textAlign: "center" }}
              >
                {ride.name} - {reverse ? "Retour" : "Aller"}
              </Typography>
              <Stepper activeStep={step} reverse={reverse}>
                {marks.map(({ label, i }) => (
                  <Step
                    reverse={reverse}
                    key={i}
                    onClick={(e) => {
                      const index = marks.findIndex(
                        (mark) => mark.label === label
                      );
                      setStep(index);
                    }}
                  >
                    <StepLabel color="primary">{""}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>

            <List sx={{ py: 0, width: "100%" }}>
              {records.map((record, i) => (
                <ListItem
                  key={record._id}
                  sx={{ p: 0, m: 0 }}
                  onClick={() => {
                    setStep(i);
                  }}
                >
                  <ListItemIcon>
                    <RadioButtonCheckedIcon
                      style={{
                        color: "#ccc",
                        ...(i < step && {
                          color: !reverse
                            ? theme.palette.primary.main
                            : theme.palette.secondary.main,
                        }),
                        ...(i === step && {
                          color: "#f9c725",
                        }),
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography variant="body2">
                          {record.stop.name}
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{ fontSize: "0.6em" }}
                        >
                          {record.stop.city.name}
                        </Typography>
                      </Stack>
                    }
                  />
                  <Box sx={{ px: 3 }}>
                    <Typography
                      variant="h6"
                      component="span"
                      color={record.count > 0 ? green[400] : red[400]}
                    >
                      {record.count === 0 ? "-" : record.count}
                    </Typography>
                  </Box>
                </ListItem>
              ))}
            </List>
            <Divider reverse={reverse} />
            <SwipeableViews
              index={step}
              onChangeIndex={(i) => {
                setStep(i);
              }}
            >
              {ride.stops.map((stop, index) => (
                <Box key={index}>
                  <Stack
                    direction="column"
                    spacing={2}
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    <Typography
                      variant="h7"
                      color="text"
                      align="center"
                      style={{ padding: 0, margin: 0 }}
                    >
                      {stop.name}&nbsp;
                      <span style={{ fontWeight: "bold" }}>
                        {records[index]?.count || 0} passager
                        {!!(records[index]?.count || 0) && "s"}
                      </span>
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={0}
                      alignItems="flex-end"
                      justifyContent="center"
                    >
                      <Box
                        sx={{
                          border: `1px solid ${grey[400]}`,
                          borderRadius: "50%",
                        }}
                      >
                        <CircleButton
                          disabled={index === records.length - 1 && !reverse}
                          onClick={() => {
                            const _records = [...records];
                            _records[index].count++;
                            if (_records[index].arrived_at === null) {
                              _records[index].arrived_at = new Date();
                            }
                            setRecords(_records);
                          }}
                        >
                          +
                        </CircleButton>
                      </Box>

                      <LittleCircleButton
                        disabled={index === records.length - 1 && !reverse}
                        onClick={() => {
                          if (recordCount) {
                            const _records = [...records];
                            _records[index].count--;
                            setRecords(_records);
                          }
                        }}
                      >
                        -
                      </LittleCircleButton>
                    </Stack>
                  </Stack>
                </Box>
              ))}
            </SwipeableViews>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {step === records.length - 1 && !reverse && (
                <Typography
                  variant="caption"
                  component="p"
                  align="center"
                  p={1}
                >
                  Vous avez terminé la course, le comptage n'est plus possible
                  au dernier arrêt
                </Typography>
              )}

              {step !== records.length - 1 && (
                <Button
                  onClick={() => {
                    setStep(step + 1);
                  }}
                  reverse={reverse}
                >
                  <Stack direction="column" spacing={0} alignItems="center">
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Valider l'arrêt
                    </Typography>
                    <Typography variant="caption" align="center">
                      ({recordCount} passager{recordCount > 1 && "s"} cumulé
                      {recordCount > 1 && "s"})
                    </Typography>
                  </Stack>
                </Button>
              )}
              {step === records.length - 1 && (
                <>
                  <Button
                    endIcon={!sendingRequest && <DoneIcon />}
                    onClick={sendRequest}
                    disabled={sendingRequest}
                    reverse={reverse}
                  >
                    {!sendingRequest && "Finaliser le circuit"}
                    {sendingRequest && (
                      <CircularProgress size={24} sx={{ color: "white" }} />
                    )}
                  </Button>
                </>
              )}
            </Box>
          </Stack>
        </Container>
      )}
    </Box>
  );
};

export default Ride;
