import sanityClient from "@sanity/client";

const SanityClient = sanityClient({
  projectId: "fjwxhrhl",
  dataset: "production",
  apiVersion: "2022-03-25",
  useCdn: false,
  ignoreBrowserTokenWarning: true,
  token:
    "skIqXD9E2QAS6cXs1pjz9doXmWm6wxF4lyC5HNucS9XWZtJgln1hajeFJsyY5IzTOIFZFhqKNjrp7jNxg4yJesrFC5lA5CcVibNRrs2U1R9dSce7WtzNIcyzoBCLKPueU6oHO1J1PXz5fTmJQYg7bH9UDC7T0UMa1UV66r6Xl1RxzNzXNd3U",
});

export default SanityClient;
