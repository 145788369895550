import {
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

import RideRow from "../components/RideRow";
import SanityClient from "../libs/SanityClient";

import logo from "../logo.svg";

const NewRide = () => {
  const [loading, setLoading] = useState(true);
  const [reverse, setReverse] = useState(false);
  const [rides, setRides] = useState([]);
  const [activeRideId, setActiveRideId] = useState(0);

  const handleReverse = () => {
    setReverse(!reverse);
  };

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    display: "flex",
    "& .MuiSwitch-switchBase": {
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
      "&.Mui-checked": {
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.secondary.main,
        },
      },
    },
  }));

  const CustomButton = styled(Button)(({ theme }) => ({
    backgroundColor: !reverse
      ? activeRideId
        ? theme.palette.primary.main
        : theme.palette.primary.light
      : activeRideId
      ? theme.palette.secondary.main
      : theme.palette.secondary.light,
    color: !activeRideId ? grey[50] : theme.palette.secondary.contrastText,
  }));

  const setActiveRide = (ride) => {
    if (activeRideId === ride._id) {
      setActiveRideId(0);
    } else {
      setActiveRideId(ride._id);
    }
  };

  useEffect(() => {
    setLoading(true);
    const query = `*[_type == "ride"]{
      _id,
      _createdAt,
      slug,
      name,
      stops[]-> {
        _id,
        name,
        slug,
        enabled,
        city->{
          name,
          slug,
        }
      }
    } | order(_createdAt asc)`;
    SanityClient.fetch(query)
      .then((res) => {
        setRides(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    console.log("activeRideId", activeRideId);
  }, []);

  return (
    <Box>
      <Container>
        {loading && (
          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            sx={{ minHeight: "100vh" }}
          >
            <CircularProgress />
          </Stack>
        )}
        {!loading && (
          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
            sx={{ minHeight: "100vh" }}
          >
            <img
              src={logo}
              alt="logo"
              style={{ height: "20%" }}
              fit="contain"
            />
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              {!!rides.length &&
                rides.map((ride) => (
                  <RideRow
                    key={ride._id}
                    ride={ride}
                    reverse={reverse}
                    setActiveRide={setActiveRide}
                    active={activeRideId === ride._id}
                  />
                ))}

              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-evenly"
                sx={{ py: 1 }}
              >
                <Typography>Aller</Typography>
                <CustomSwitch
                  inputProps={{ "aria-label": "Aller / Retour" }}
                  checked={reverse}
                  onChange={handleReverse}
                />
                <Typography>Retour</Typography>
              </Stack>
            </Box>
            <CustomButton
              disabled={!activeRideId}
              onClick={() => {
                localStorage.setItem("rideStartDate", new Date().toISOString());
                if (reverse) {
                  window.location.href = `/retour/${activeRideId}`;
                } else {
                  window.location.href = `/aller/${activeRideId}`;
                }
              }}
            >
              Choisir ce circuit
            </CustomButton>
            <Button
              variant="text"
              size="small"
              onClick={() => {
                window.location.href = "/accueil";
              }}
            >
              {"< Retour à l'accueil"}
            </Button>
          </Stack>
        )}
      </Container>
    </Box>
  );
};

export default NewRide;
