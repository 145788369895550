import { useEffect, useState } from "react";
import logo from "../logo.svg";
import bg from "../bg.jpg";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";

import FaceIcon from "@mui/icons-material/Face";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import md5 from "md5";

import SanityClient from "../libs/SanityClient";
import { config } from "../config/config";
import { convertLongDate } from "../libs/utils";

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loginEnabled, setLoginEnabled] = useState(false);
  const [everTouched, setEverTouched] = useState(true);

  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [hashedPassword, setHashedPassword] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const action = (snackbarId) => (
    <CircularProgress size={20} sx={{ color: "white", px: 2 }} />
  );

  useEffect(() => {
    const userStr = localStorage.getItem("user");
    const user = JSON.parse(userStr);
    if (user) {
      setEverTouched(true);
      const query = `*[_type == "user" && _id == "${user._id}"]`;
      SanityClient.fetch(query)
        .then((res) => {
          if (res.length > 0) {
            enqueueSnackbar("Vous êtes déjà connecté", {
              variant: "success",
              autoHideDuration: 1000,
              preventDuplicate: true,
              action,
              onClose: () => {
                window.location.href = "/accueil";
              },
            });
          } else {
            localStorage.removeItem("user");
            setEverTouched(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setEverTouched(false);
        });
    } else {
      setEverTouched(false);
    }
  }, []);

  useEffect(() => {
    if (login.length > 0 && password.length > 0) {
      setLoginEnabled(true);
    } else {
      setLoginEnabled(false);
    }
  }, [login, password]);

  useEffect(() => {
    setHashedPassword(md5(password));
  }, [password]);

  const handleLogin = () => {
    setLoading(true);
    const query = `*[_type == "user" && login == "${login}" && hashedPassword == "${hashedPassword}"]`;
    SanityClient.fetch(query)
      .then((res) => {
        if (res.length > 0) {
          localStorage.setItem("user", JSON.stringify(res[0]));
          enqueueSnackbar("Connexion réussie", {
            variant: "success",
            autoHideDuration: 1000,
            preventDuplicate: true,
            action,
            onClose: () => {
              setLoading(false);
              window.location.href = "/accueil";
            },
          });
        } else {
          localStorage.removeItem("user");
          setLoading(false);
          setPassword("");
          enqueueSnackbar("Connexion échouée", {
            variant: "error",
            preventDuplicate: true,
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Une erreur a eu lieu", {
          variant: "error",
        });
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{ minHeight: "100vh", backgroundImage: `url(${bg})` }}
      style={{ backgroundSize: "cover" }}
    >
      <Container sx={{ minHeight: "100vh" }}>
        <Stack
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={2}
          sx={{ minHeight: "100vh" }}
        >
          <img src={logo} alt="logo" style={{ height: "20%" }} fit="contain" />

          {!everTouched && (
            <Stack
              direction="column"
              spacing={2}
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Typography variant="h6" mb={4}>
                Connexion
              </Typography>
              <TextField
                name="email"
                type="text"
                placeholder="Nom d'utilisateur"
                size="small"
                variant="outlined"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FaceIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ backgroundColor: "white" }}
                fullWidth
              />
              <TextField
                name="password"
                type={!showPassword ? "password" : "text"}
                placeholder="Mot de passe"
                size="small"
                sx={{ backgroundColor: "white" }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        sx={{ px: 0 }}
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                disabled={loading}
                sx={{ width: "50vw" }}
                onClick={handleLogin}
                style={{ opacity: loginEnabled ? 1 : 0.5 }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Connexion"
                )}
              </Button>
            </Stack>
          )}
          <Box></Box>
        </Stack>
      </Container>
      <Box sx={{ mt: 6 }}>
        <Typography
          variant="caption"
          align="center"
          style={{ textAlign: "center" }}
          display="block"
          color="white"
        >
          version <b>{process.env.REACT_APP_VERSION || ""}</b> - (build number:{" "}
          <b>{config.BUILD_VERSION}</b> - build date:{" "}
          <b>{convertLongDate(config.BUILD_DATE)}</b>)
        </Typography>
        <Typography
          variant="caption"
          align="center"
          style={{ textAlign: "center" }}
          display="block"
          color="white"
        >
          {new Date(config.BUILD_DATE).getFullYear()} © neodigit
        </Typography>
      </Box>
    </Box>
  );
};

export default LoginForm;
