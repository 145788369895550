import { Alert, Box, Button, Container, Stack } from "@mui/material";

import logo from "../logo.svg";
import bg from "../bg.jpg";

const HomePage = () => {
  return (
    <Box
      sx={{ minHeight: "100vh", backgroundImage: `url(${bg})` }}
      style={{ backgroundSize: "cover" }}
    >
      <Container sx={{ minHeight: "100vh" }}>
        <Stack
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={2}
          sx={{ minHeight: "100vh" }}
        >
          <img src={logo} alt="logo" style={{ height: "20%" }} fit="contain" />
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Button
              color="primary"
              onClick={() => {
                window.location.href = "/nouveau-circuit";
              }}
            >
              Nouveau circuit
            </Button>
            <Button
              variant="outlined"
              color="primary"
              sx={{ backgroundColor: "white" }}
              onClick={() => {
                window.location.href = "/statistiques";
              }}
            >
              Statistiques
            </Button>
            <Button
              variant="text"
              onClick={() => {
                localStorage.clear();
                window.location.href = "/";
              }}
            >
              Se déconnecter
            </Button>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default HomePage;
