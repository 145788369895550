import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import SanityClient from "../libs/SanityClient";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import FlagIcon from "@mui/icons-material/Flag";
import TimerIcon from "@mui/icons-material/Timer";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import FastForwardIcon from "@mui/icons-material/FastForward";
import { Label } from "@mui/icons-material";

const StatsDetails = () => {
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [record, setRecord] = useState({});
  const [count, setCount] = useState(0);
  const { recordId } = useParams();

  const theme = useTheme();

  useEffect(() => {
    const userStr = localStorage.getItem("user");
    const user = JSON.parse(userStr);
    const query = `*[_type == "record" && _id == "${recordId}" && user._ref == "${user._id}"]{
        _id,
        started_at,
        completed_at,
        reverse,
        ride->{
          _id,
          name,
        },
        stop_records[]{
            _key,
            arrived_at,
            count,
            stop->{
                _id,
                name,
            },
        }
      }`;
    SanityClient.fetch(query)
      .then((record) => {
        setRecords(record);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (records.length === 0) {
      return;
    }
    setRecord(records[0]);
  }, [records]);

  useEffect(() => {
    console.log(record);
    if (Object.keys(record).length === 0) {
      return;
    }
    let _count = 0;
    record.stop_records.forEach((stop_record) => {
      _count += stop_record.count;
    });
    setCount(_count);
  }, [record]);

  const { reverse } = record;

  return (
    <Box sx={{ background: "#f5f7ff", minHeight: "100vh" }}>
      {loading && (
        <Stack
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ minHeight: "100vh" }}
        >
          <CircularProgress />
        </Stack>
      )}
      {!loading && Object.keys(record).length && (
        <Box py={2}>
          <Typography variant="h6" align="center">
            {record.ride.name} - {record.reverse ? "Retour" : "Aller"}
          </Typography>
          <Typography variant="body2" align="center">
            {new Date(record.started_at).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
            &nbsp;-&nbsp;
            {new Date(record.completed_at).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            pt={2}
            px={2}
          >
            <CalendarMonthIcon
              sx={{ mr: 2, color: theme.palette.accent.main }}
            />
            <Typography color={theme.palette.accent.main} fontWeight="bold">
              {new Date(record.started_at).toLocaleDateString("fr-FR", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            px={2}
          >
            <PlayArrowIcon
              sx={{
                mr: 2,
                color: reverse
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main,
              }}
            />
            <Typography
              color={
                reverse
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main
              }
              fontWeight="bold"
            >
              Départ:{" "}
              {new Date(record.started_at).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            px={2}
          >
            <FlagIcon
              sx={{
                mr: 2,
                color: reverse
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main,
              }}
            />
            <Typography
              color={
                reverse
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main
              }
              fontWeight="bold"
            >
              Arrivée:{" "}
              {new Date(record.completed_at).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            px={2}
          >
            <TimerIcon
              sx={{
                mr: 2,
                color: reverse
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main,
              }}
            />
            <Typography
              color={
                reverse
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main
              }
              fontWeight="bold"
            >
              Durée:{" "}
              {Math.floor(
                (new Date(record.completed_at) - new Date(record.started_at)) /
                  1000 /
                  60
              )}{" "}
              minutes
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
            p={3}
            px={9}
          >
            <Badge
              badgeContent={
                <Box
                  sx={{
                    display: "flex",
                    direction: "row",
                    alignItems: "space-between",
                    justifyContent: "space-between",
                  }}
                >
                  {record.reverse ? (
                    <FastRewindIcon sx={{ mr: 2 }} />
                  ) : (
                    <FastForwardIcon sx={{ mr: 2 }} />
                  )}
                  <Typography>{record.reverse ? "Retour" : "Aller"}</Typography>
                </Box>
              }
              color="accent"
              sx={{
                color: "white",
              }}
            />
          </Box>
          <Divider reverse={reverse} sx={{ my: 2 }} />
          <Typography
            color={reverse ? "secondary" : "primary"}
            fontWeight="bold"
            sx={{ px: 2 }}
          >
            Arrêts
          </Typography>
          <Box py={2}>
            {record.stop_records.map((stop) => (
              <Box
                key={stop._key}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  px: 2,
                }}
              >
                <Typography>
                  {new Date(stop.arrived_at).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </Typography>
                <Typography>{stop.stop.name}</Typography>

                <Typography>{stop.count}</Typography>
              </Box>
            ))}
          </Box>
          <Divider reverse={reverse} sx={{ my: 2 }} />
          <Typography variant="h6" align="center">
            {count} passager{count > 1 ? "s" : ""} {count > 1 && "au total"}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
            <Button
              {...{ reverse }}
              onClick={() => {
                window.location.href = "/accueil";
              }}
            >
              Retour à l'accueil
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default StatsDetails;
