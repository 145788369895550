import { Box, Chip, Stack, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { purple, blue, grey } from "@mui/material/colors";
import { createTheme, styled } from "@mui/material/styles";

const RideRow = ({ ride, active, reverse, setActiveRide }) => {
  const CustomBox = styled(Box)(({ theme }) => ({
    borderRadius: theme.spacing(2),
    backgroundColor: grey[50],
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    border: `1px solid ${grey[100]}`,
  }));

  const CustomStack = styled(Stack)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    spacing: 2,
    borderRadius: theme.spacing(2),
    elevation: 3,
    backgroundColor: active
      ? !reverse
        ? theme.palette.primary.main
        : theme.palette.secondary.main
      : !reverse
      ? theme.palette.primary.light
      : theme.palette.secondary.light,
    color: active
      ? theme.palette.primary.contrastText
      : theme.palette.primary.text,
  }));

  const CustomChip = styled(Chip)(({ theme }) => ({
    backgroundColor: active
      ? !reverse
        ? theme.palette.primary.main
        : theme.palette.secondary.main
      : !reverse
      ? theme.palette.primary.light
      : theme.palette.secondary.light,
    color: active
      ? theme.palette.primary.contrastText
      : theme.palette.primary.text,
    marginTop: theme.spacing(1),
  }));

  return (
    <CustomBox
      onClick={() => {
        setActiveRide(ride);
      }}
    >
      <CustomStack>
        <Typography variant="h6" sx={{ p: 1 }} component="div">
          {ride.name}
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          {ride.stops.map((stop) => (
            <Typography key={stop._id}>&bull;</Typography>
          ))}
        </Stack>
        {!active && <RadioButtonUncheckedIcon size="36" sx={{ mr: 1 }} />}
        {active && <CheckCircleIcon size="36" sx={{ mr: 1 }} />}
      </CustomStack>
      <Stack
        direction={!reverse ? "row" : "row-reverse"}
        spacing={1}
        alignItems="center"
        justifyContent="space-evenly"
        sx={{ px: 2 }}
      >
        <Stack direction="column" spacing={1}>
          <CustomChip
            label={
              <Typography variant="caption" sx={{ fontSize: "0.55rem" }}>
                {ride.stops[0].city.name}
              </Typography>
            }
            size="small"
          />
          <Typography variant="caption" align="center">
            {ride.stops[0].name}
          </Typography>
        </Stack>

        <Stack direction="column" spacing={1}>
          <CustomChip
            label={
              <Typography
                variant="caption"
                align={reverse ? "right" : "left"}
                sx={{ fontSize: "0.55rem" }}
              >
                {ride.stops[ride.stops.length - 1].city.name}
              </Typography>
            }
            size="small"
          />
          <Typography variant="caption" align="center">
            {ride.stops[ride.stops.length - 1].name}
          </Typography>
        </Stack>
      </Stack>
    </CustomBox>
  );
};

export default RideRow;
