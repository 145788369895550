import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { SnackbarProvider } from "notistack";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginForm from "./pages/LoginForm";
import ErrorPage from "./pages/ErrorPage";
import HomePage from "./pages/HomePage";
import NewRide from "./pages/NewRide";
import Ride from "./pages/Ride";
import Purge from "./pages/Purge";
import { ThemeProvider } from "@mui/material";
import theme from "./style/theme";
import Stats from "./pages/Stats";
import StatsDetails from "./pages/StatsDetails";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginForm />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/accueil",
    element: <HomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/nouveau-circuit",
    element: <NewRide />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/statistiques/:recordId",
    element: <StatsDetails />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/statistiques",
    element: <Stats />,
    errorElement: <ErrorPage />,
  },

  {
    path: "/aller/:rideId",
    element: <Ride reverse={false} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/retour/:rideId",
    element: <Ride reverse={true} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/purge",
    element: <Purge />,
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SnackbarProvider maxSnack={3}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </SnackbarProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
