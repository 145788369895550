import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SanityClient from "../libs/SanityClient";

import { useEffect, useState } from "react";

const Stats = () => {
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [days, setDays] = useState([]);

  const theme = useTheme();

  useEffect(() => {
    const userStr = localStorage.getItem("user");
    const user = JSON.parse(userStr);
    const query = `*[_type == "record" && user._ref == "${user._id}"]| order(dateTime(started_at) desc){
      _id,
      started_at,
      completed_at,
      reverse,
      ride->{
        _id,
        name,
      },
    }`;
    SanityClient.fetch(query)
      .then((records) => {
        setRecords(records);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const _days = [];

    records.forEach((record) => {
      const date = new Date(record.started_at);
      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();
      const dayStr = `${day}/${month}/${year}`;
      if (!Object.keys(_days).includes(dayStr)) {
        _days[dayStr] = [];
      }
      _days[dayStr] = [..._days[dayStr], record];
    });
    setDays(_days);
  }, [records]);

  return (
    <Box sx={{ background: "#f5f7ff", minHeight: "100vh" }}>
      {loading && (
        <Stack
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ minHeight: "100vh" }}
        >
          <CircularProgress />
        </Stack>
      )}
      {!loading && (
        <Stack>
          <List
            sx={{
              width: "100%",
              position: "relative",
              overflow: "auto",
              maxHeight: "100vh",
              "& ul": { padding: 0 },
            }}
            subheader={<li />}
          >
            {Object.keys(days).map((sectionId) => (
              <li key={`section-${sectionId}`}>
                <ul>
                  <ListSubheader sx={{ backgroundColor: "transparent" }}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      py={2}
                    >
                      <Divider
                        sx={{ borderColor: theme.palette.accent.main }}
                      />
                      <Badge
                        color="accent"
                        style={{
                          position: "absolute",
                          left: "50%",
                          color: "white",
                        }}
                        badgeContent={
                          <Typography
                            variant="h5"
                            sx={{ p: 2 }}
                          >{`${sectionId}`}</Typography>
                        }
                      ></Badge>
                    </Box>
                  </ListSubheader>
                  {days[sectionId].map((item, i) => (
                    <ListItem
                      key={i}
                      secondaryAction={
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                          onClick={() => {
                            window.location.href = `/statistiques/${item._id}`;
                          }}
                        >
                          <ArrowForwardIcon
                            color={item.reverse ? "secondary" : "primary"}
                          ></ArrowForwardIcon>
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary={
                          <Box
                            display="flex"
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            pr={2}
                          >
                            <Typography
                              color={item.reverse ? "secondary" : "primary"}
                              fontWeight="bold"
                            >
                              {item.ride.name} -{" "}
                              {item.reverse ? "Retour" : "Aller"}
                            </Typography>
                            <Typography>
                              {new Date(item.started_at).toLocaleTimeString()}
                              &nbsp;-&nbsp;
                              {new Date(item.completed_at).toLocaleTimeString()}
                            </Typography>
                          </Box>
                        }
                      />
                    </ListItem>
                  ))}
                </ul>
              </li>
            ))}
          </List>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 2,
            }}
          >
            <Button
              sx={{ background: theme.palette.accent.main }}
              onClick={() => {
                window.location.href = "/accueil";
              }}
            >
              Retour à l'accueil
            </Button>
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default Stats;
