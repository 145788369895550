export function convertShortDate(date_value) {
  const dateOptions = {
    // weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const date = new Date(date_value);

  return date.toLocaleDateString("fr-FR", dateOptions);
}

export function convertLongDate(date_value) {
  const dateOptions = {
    // weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const date = new Date(date_value);

  return (
    date.toLocaleDateString("fr-FR", dateOptions) +
    " " +
    date.toLocaleTimeString("fr-FR")
  );
}
