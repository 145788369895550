import { Alert, Box, Button, Container, Stack } from "@mui/material";

import logo from "../logo.svg";
import bg from "../bg.jpg";

const ErrorPage = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundImage: `url(${bg})`,
      }}
      style={{ backgroundSize: "cover" }}
    >
      <Container sx={{ minHeight: "100vh" }}>
        <Stack
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={2}
          sx={{ minHeight: "100vh" }}
        >
          <img src={logo} alt="logo" style={{ height: "20%" }} fit="contain" />
          <Alert severity="error">Cette page n'existe pas</Alert>
          <Button
            color="primary"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Retour à l'accueil
          </Button>
        </Stack>
      </Container>
    </Box>
  );
};

export default ErrorPage;
