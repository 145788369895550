import { Box, Container, Stack } from "@mui/material";

import logo from "../logo.svg";
import bg from "../bg.jpg";
import { useEffect } from "react";
import SanityClient from "../libs/SanityClient";

const Purge = () => {
  useEffect(() => {
    SanityClient.delete({ query: '*[_type == "record"][0...999]' })
      .then(() => {
        SanityClient.delete({ query: '*[_type == "stop_record"][0...999]' })
          .then(() => {
            window.location.href = "/";
          })
          .catch(console.error);
      })
      .catch(console.error);
  }, []);

  return (
    <Box
      sx={{ minHeight: "100vh", backgroundImage: `url(${bg})` }}
      style={{ backgroundSize: "cover" }}
    >
      <Container sx={{ minHeight: "100vh" }}>
        <Stack
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={2}
          sx={{ minHeight: "100vh" }}
        >
          <img src={logo} alt="logo" style={{ height: "20%" }} fit="contain" />
        </Stack>
      </Container>
    </Box>
  );
};

export default Purge;
