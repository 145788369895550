import { createTheme } from "@mui/material";
import { blue, purple } from "@mui/material/colors";

let theme = createTheme({
  palette: {
    primary: {
      main: blue[500],
      light: blue[50],
      dark: blue[800],
    },
    secondary: {
      main: purple[500],
      light: purple[50],
      dark: purple[800],
    },
    accent: {
      main: "#fab604",
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiStepper: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: theme.spacing(2),
          padding: theme.spacing(1),
          marginTop: theme.spacing(1),
          ...(ownerState?.reverse
            ? {
                backgroundColor: theme.palette.secondary.light,
              }
            : {
                backgroundColor: theme.palette.primary.light,
              }),
        }),
      },
    },
    MuiStep: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: 0,
          ".MuiStepLabel-iconContainer": {
            padding: 0,
          },
          ".MuiStepIcon-root": {
            width: theme.spacing(2),
            height: theme.spacing(2),
            padding: 0,
            ".MuiStepIcon-text": {
              display: "none",
            },
            color: ownerState?.reverse
              ? theme.palette.secondary.main
              : theme.palette.primary.main,
            "&.Mui-completed": {
              color: ownerState?.reverse
                ? theme.palette.secondary.main
                : theme.palette.primary.main,
            },
            "&.Mui-active": {
              color: ownerState?.reverse
                ? theme.palette.secondary.dark
                : theme.palette.primary.dark,
            },
          },
        }),
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: ({ ownerState }) => ({
          padding: theme.spacing(1),
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: theme.spacing(2),
          borderRadius: theme.spacing(5),
          ...(ownerState?.reverse && ownerState?.variant === "contained"
            ? {
                backgroundColor: theme.palette.secondary.main,
                "&:hover": {
                  backgroundColor: theme.palette.secondary.main,
                },
              }
            : ownerState?.variant === "contained" && {
                backgroundColor: theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                },
              }),
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          width: "100%",
          marginTop: 0,
          ...(ownerState?.reverse
            ? {
                borderColor: theme.palette.secondary.main,
              }
            : {
                borderColor: theme.palette.primary.main,
              }),
        }),
      },
    },
    MuiStack: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          marginY: 0,
        }),
      },
    },
  },
});

export default theme;
